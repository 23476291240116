@font-face {
	font-family: "Poppins-Bold";
	src: local("Poppins-Bold"),
		url("../public/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-Regular";
	src: local("Poppins-Regular"),
		url("../public/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-SemiBold";
	src: local("Poppins-SemiBold"),
		url("../public/fonts/Poppins-SemiBold.ttf") format("truetype");
}

h1 {
	line-height: 43px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: Poppins-Bold;
	line-height: 30px;
}

p {
	margin-top: 0;
	font-family: Poppins-Regular;
	font-size: 16px;
	line-height: 30px;
}

body {
	margin: 0px;
	padding: 0px;
	font-family: Poppins-Regular;
	line-height: 1.5rem;
}

html {
	height: 100%;
}


.MuiListItemButton-root:hover {
	background-color: #c7f0dc !important;
	transition: 0.5s;
}



ol > li {
	line-height: 30px;
}

.scroll {
	min-height: calc(100vh - 80px);
	overflow: auto;
}

.whiteText {
	color: white;
}

/* ::-webkit-scrollbar {
	width: 5px;
	height: 3px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgb(255, 255, 255);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: #1565c0;
	border-radius: 10px;
} */

.timer-wrapper {
	display: flex;
	justify-content: flex-start;
}

.timer {
	font-family: "Roboto";
	display: flex;
	flex-direction: column;
	align-items: center;
}

.text {
	color: #aaa;
}

.value {
	font-size: 40px;
}

.info {
	margin: 40px auto 0;
	font-size: 25px;
	line-height: 45px;
}

.title {
	margin: 40px auto 0;
	font-size: 35px;
	line-height: 55px;
	text-align: center;
}

.option-card {
	font-size: 18px;
	display: flex;
	justify-content: center;
	padding: 12px 10px;
	/* width: 275px; */
	height: 70%;
	text-align: center;
	align-items: center;
	cursor: pointer;
	border-radius: 14px !important;
	transition: 0.5s !important;
	flex-direction: column;
}

.cardgreen {
	color: #5e8d48;
	font-size: 35px;
	margin-bottom: 5px;
}

.cardblue {
	color: #1976d2;
	font-size: 35px;
	margin-bottom: 5px;
}

.cardpurple {
	color: #ad0c7f;
	font-size: 35px;
	margin-bottom: 5px;
}

.cardorange {
	color: #ffa929;
	font-size: 35px;
	margin-bottom: 5px;
}

.cardselected {
	color: #fff;
	font-size: 35px;
	margin-bottom: 5px;
}

.highlight-text-yellow {
	color: #ffff00;
}

.MuiInputLabel-root {
	font-size: 25px !important;
}

@media (max-width: 600px) {
	h1 {
		font-size: 22px;
		line-height: 30px !important;
	}

	h4 {
		font-size: 22px;
	}
	.timer-wrapper {
		display: flex;
		justify-content: center;
	}
	
}

@media (max-width: 480px) {
/* .scroll {
		min-height: calc(100vh - 130px);
		overflow: scroll;
	} */
}
