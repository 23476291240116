@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
   .button {
      @apply font-websa-bold transition-all bg-websa-red hover:bg-websa-green p-2.5 rounded shadow-sm text-white font-bold text-sm 
   }
   .button-small {
      @apply w-[100px] sm:w-auto font-websa-regular transition-all bg-websa-red hover:bg-websa-green py-2 px-2 rounded shadow-sm text-white font-bold text-xs
   }
   .button-small-green {
      @apply w-[100px] sm:w-auto font-websa-regular transition-all bg-websa-green hover:bg-websa-red py-2 px-2 rounded shadow-sm text-white font-bold text-xs
   }
   .contained {
      @apply font-websa-bold transition-all bg-websa-red hover:bg-websa-green px-3 py-1 rounded shadow-sm text-white font-bold text-sm 
   }

   .outlined {
      @apply font-websa-regular transition-all border border-slate-300 px-3 py-1 hover:bg-slate-200 rounded shadow-sm text-black font-bold text-sm 
   }

   .heading1 {
      @apply font-websa-bold text-xl sm:text-2xl mb-5
   }

   .holder {
      @apply pt-20 sm:pt-36
   }
}
